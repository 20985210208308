<template>
  <div>
    <!--  备案凭证 -->
    <div class="fromDiv">
      <div class="tableTitle">
        <span class="title"> 备案凭证 </span>
      </div>
      <div class="formLine">
        <FromLabel label="备案凭证" :showIcon="true" :fontColor="changeList.includes('record_file_name')"></FromLabel>
        <div class="formFlIpt647 fl">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件" readonly v-model="postFrom.record_file_name">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="openFile">{{ postFrom.record_file_name }}</span> -->
          <span class="lookupImg" v-if="postFrom.record_file_key && postFrom.record_code" @click="openFile">{{ postFrom.record_file_name }}-备案凭证</span>
          <span class="lookupImg" v-else-if="postFrom.record_file_key && !postFrom.record_code" @click="openFile">-备案凭证</span>
        </div>
        <FromLabel label="产品分类" :showIcon="true" :fontColor="changeList.includes('record_product_categories')"></FromLabel>
        <div class="formFlIpt647 fl">
          <Select v-model="postFrom.record_product_categories" class="iviewIptWidth307">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <!-- <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_code" :readonly="status != 3 && status != 2"></i-input> -->
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案号" :showIcon="true" :fontColor="changeList.includes('record_code')"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" @on-change="changeLicenceCode" v-model.trim="postFrom.record_code" :readonly="status != 3 && status != 2"></i-input>
        </div>
        <FromLabel label="备案人组织机构代码" :fontColor="changeList.includes('recorder_org_code')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.recorder_org_code" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案人名称" :showIcon="true" :fontColor="changeList.includes('record_name')"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_name" :readonly="status != 3 && status != 2"></i-input>
        </div>
        <FromLabel label="生产地址" :fontColor="changeList.includes('record_production_address')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_production_address" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案人注册地址" :fontColor="changeList.includes('record_creator_company_address')"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_creator_company_address" :readonly="status != 3 && status != 2"></i-input>
        </div>
        <FromLabel label="代理人注册住址" :fontColor="changeList.includes('record_proxy_address')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_proxy_address" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="代理人" :fontColor="changeList.includes('record_proxy_name')"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.record_proxy_name" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="产品描述" :fontColor="changeList.includes('product_desc')"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.product_desc" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="预备用途" :fontColor="changeList.includes('preliminary_use')"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.preliminary_use" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案单位" :fontColor="changeList.includes('record_department')"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.record_department" :readonly="status != 3 && status != 2"></i-input>
        </div>
        <FromLabel label="备案日期" :showIcon="true" :fontColor="changeList.includes('record_time')"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="postFrom.record_time" :readonly="status != 3 && status != 2" @on-change="changeTime"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备注" :fontColor="changeList.includes('record_remark')"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.record_remark" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
    </div>
    <div class="tableTitle">
      <span class="title"> 规格型号 </span>
      <router-link class="pageBtn finger btnReset marginLeft20" :to="{ path: '/dataDictionary' }" tag="a" target="_blank">产品字典</router-link>
      <span class="fontColorred">（注：请填写最小销售单位的单价）</span>
    </div>
    <ux-grid border show-overflow="true" ref="plxTable" :height="height" widthResize @table-body-scroll="scroll" style="width: 100%; position: relative;" :edit-config="{ trigger: 'click', mode: 'cell' }">
      <ux-table-column type="index" align="center" title="序号" width="60"></ux-table-column>
      <!--autofocus，如果是自定义渲染可以指定聚焦的选择器，他的作用很明显就是你点击一下就帮你去获取焦点。
      你无需点击第二次再去获取焦点。autofocus的值就是你编辑型输入框（edit）组件的类名-->
      <ux-table-column field="product_model_code" align="center" title="产品编号" width="190" :edit-render="{ autofocus: '.ivu-input' }">
        <template v-slot:edit="scope">
          <!--autofocus绑定的类名就是这个组件的class类名。帮助获取输入框焦点-->
          <i-input v-model="scope.row.product_model_code" class="onlyzero" @on-change="changeProductCode($event, scope.rowIndex)" :readonly="(postFrom.check_state == 2 || postFrom.check_state == 3) && status != 3 && status != 2"></i-input>
        </template>
        <!--写非编辑状态下的样式, 看最底部的style样式部分吧 有写my-input-sc样式哦-->
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'onlyonce', 'textLeft', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('product_model_code') ? 'redColor' : '']">{{ scope.row.product_model_code }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="product_code_child" align="center" title="子物料号" width="190" :edit-render="{ autofocus: '.ivu-input' }">
        <template v-slot:edit="scope">
          <i-input placeholder="请输入" class="onlyonce2" v-model="scope.row.product_code_child" @on-change="changeCodeChild($event, scope.rowIndex)" :readonly="(postFrom.check_state == 2 || postFrom.check_state == 3) && status != 3 && status != 2"></i-input>
        </template>
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'textLeft', 'onlytwice', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('product_code_child') ? 'redColor' : '']">{{ scope.row.product_code_child }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="model_name" align="center" title="规格型号" width="200" :edit-render="{ autofocus: '.ivu-input-default' }">
        <template v-slot:edit="scope">
          <i-input placeholder="请输入" class="onlytwice2" :disabled="Boolean(Number(scope.row.is_approved))" @on-change="changeModelnName($event, scope.rowIndex)" v-model="scope.row.model_name" :readonly="(postFrom.check_state == 2 || postFrom.check_state == 3) && status != 3 && status != 2"></i-input>
        </template>
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'textLeft', 'onlythird', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('model_name') ? 'redColor' : '']">{{ scope.row.model_name }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="item_number" align="center" title="货号/SKU" width="190" :edit-render="{ autofocus: '.ivu-input-default' }">
        <template v-slot:edit="scope">
          <i-input placeholder="请输入" class="onlythird2" v-model="scope.row.item_number" @on-change="changeItemNum($event, scope.rowIndex)" :readonly="(postFrom.check_state == 2 || postFrom.check_state == 3) && status != 3 && status != 2"></i-input>
        </template>
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'textLeft', 'onlyfourth', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('item_number') ? 'redColor' : '']">{{ scope.row.item_number }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="product_code_child" align="center" title="最小包装规格" width="380" :edit-render="{ autofocus: '.ivu-input-number-input' }">
        <template v-slot:edit="scope">
          <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="scope.row.unit.qty" placeholder="请输入" class="iviewIptWidth100 marginRight18"></InputNumber>
          <Select class="iviewIptWidth100 onlysixth" placeholder="请选择" filterable v-model="scope.row.unit.unit" allow-create clearable ref="unitqty" @on-change="changeUnitUnit(scope.row.unit.unit, 'unit', 'unit', scope.rowIndex)" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
          </Select>
          <span class="xiegang">/</span>
          <Select class="iviewIptWidth100 onlyseventh" placeholder="请选择" filterable v-model="scope.row.unit.dose_unit" allow-create clearable ref="unitqty" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
          </Select>
          <span class="spin">(单位)</span>
        </template>
        <template v-slot="scope">
          <span :class="[scope.row.recently_modified_field && scope.row.recently_modified_field.includes('unit') ? 'redColor' : '']">{{ scope.row.unit.qty }} {{ scope.row.unit.unit }}<span class="xiegang">/</span>{{ scope.row.unit.dose_unit }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="product_code_child" title="包装规格" class="onlynum2" align="center" width="380" :edit-render="{ autofocus: '.ivu-input-number-input' }">
        <template v-slot:edit="scope">
          <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="scope.row.packing_size.qty" placeholder="请输入" class="iviewIptWidth100 onlyeighth marginRight18"></InputNumber>
          <Select class="iviewIptWidth100 onlyninth" placeholder="请选择" filterable v-model="scope.row.packing_size.unit" allow-create clearable ref="unitqty" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
          </Select>
          <span class="xiegang">/</span>
          <Select class="iviewIptWidth100 onlytenth" placeholder="请选择" filterable v-model="scope.row.packing_size.dose_unit" allow-create clearable ref="unitqty" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
          </Select>
          <span class="spin">(单位)</span>
        </template>
        <template v-slot="scope">
          <span :class="[scope.row.recently_modified_field && scope.row.recently_modified_field.includes('packing_size') ? 'redColor' : '']">{{ scope.row.packing_size.qty }} {{ scope.row.packing_size.unit }}<span class="xiegang">/</span>{{ scope.row.packing_size.dose_unit }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="unit_price" title="单价" align="center" width="150" :edit-render="{ autofocus: '.ivu-input-number-input' }">
        <template v-slot:edit="scope">
          <InputNumber :active-change="false" :max="9999999" :min="0" class="my-input-sc" @on-blur="notax_unit_priceChange(scope.row, scope.rowIndex)" v-model="scope.row.unit_price" placeholder="请输入" :readonly="status != 3 && status != 2"></InputNumber>
        </template>
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'onlyeleven2', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('unit_price') ? 'redColor' : '']">{{ scope.row.unit_price }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="tax_rate" title="税率(%)" align="center" width="150" :edit-render="{ autofocus: '.ivu-input-number-input' }">
        <template v-slot:edit="scope">
          <InputNumber :active-change="false" :max="9999999" :min="0" class="my-input-sc onlytwelfth" @on-blur="notax_unit_priceChange(scope.row, scope.rowIndex)" v-model="scope.row.tax_rate" placeholder="请输入" :readonly="status != 3 && status != 2"></InputNumber>
        </template>
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'onlytwelfth2', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('tax_rate') ? 'redColor' : '']">{{ scope.row.tax_rate }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="notax_unit_price" align="center" title="不含税单价" width="150">
        <template v-slot="scope">
          <span :class="['bludColor', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('notax_unit_price') ? 'redColor' : '']">{{ scope.row.notax_unit_price }}</span>
        </template>
      </ux-table-column>
      <ux-table-column field="valid_days" title="货架有效天数（保质期）" align="center" width="205" :edit-render="{ autofocus: '.ivu-input-number-input' }">
        <template v-slot:edit="scope">
          <InputNumber :active-change="false" :max="9999999" :min="0" class="my-input-sc" v-model="scope.row.valid_days" placeholder="请输入" :readonly="status != 3 && status != 2"></InputNumber>
        </template>
        <template v-slot="scope">
          <span :class="['my-input-sc', 'nowrap', 'onlythirteenth2', scope.row.recently_modified_field && scope.row.recently_modified_field.includes('valid_days') ? 'redColor' : '']">{{ scope.row.valid_days }}</span>
        </template>
      </ux-table-column>
      <ux-table-column width="170" fixed field="describe" align="center" title="操作" :fixed="right">
        <template v-slot="scope">
          <div class="lastDiv">
            <div @click="codeReview(scope.row, scope.rowIndex)">条码维护</div>
            <div @click="banGoods(scope.row, scope.rowIndex)">{{ scope.row.check_status == 1 ? '禁用' : '启用' }}</div>
            <div @click="deleteRow(scope.row, scope.rowIndex)">删除</div>
          </div>
        </template>
      </ux-table-column>
    </ux-grid>
    <!-- <Table :columns="listColumns" :data="listData" border no-data-text="暂无规格型号">
      <template slot-scope="{ row, index }" slot="product_model_code">
        <i-input placeholder="请输入" @on-blur="changeIpt($event, 'product_model_code', index)" :value="row.product_model_code" :readonly="(postFrom.check_state == 2 || postFrom.check_state == 3) && status != 3 && status != 2"></i-input>
      </template>
      <template slot-scope="{ row, index }" slot="product_code_child">
        <i-input placeholder="请输入" @on-blur="changeIpt($event, 'product_code_child', index)" :value="row.product_code_child" :readonly="(postFrom.check_state == 2 || postFrom.check_state == 3) && status != 3 && status != 2"></i-input>
      </template>
      <template slot-scope="{ row, index }" slot="model_name">
        <i-input placeholder="请输入" @on-blur="changeIpt($event, 'model_name', index)" :value="row.model_name" :readonly="status != 3 && status != 2"></i-input>
      </template>
      <template slot-scope="{ row, index }" slot="item_number">
        <i-input placeholder="请输入" @on-blur="changeIpt($event, 'item_number', index)" :value="row.item_number" :readonly="status != 3 && status != 2"></i-input>
      </template>
      <template slot-scope="{ row, index }" slot="unit_price">
        <InputNumber :active-change="false" :max="9999999" :min="1" class="iviewIptWidth100" style="height: 38px;" v-model="row.unit_price" placeholder="请输入" @on-change="changeIpt($event, 'unit_price', index)" :readonly="status != 3 && status != 2"></InputNumber>
      </template>
      <template slot-scope="{ row, index }" slot="tax_rate">
        <InputNumber :active-change="false" :max="100" :min="1" class="iviewIptWidth100" style="height: 38px;" :precision="0" v-model="row.tax_rate" placeholder="请输入" @on-change="changeIpt($event, 'tax_rate', index)" :readonly="status != 3 && status != 2"></InputNumber>
      </template>
      新增表格
      <template slot-scope="{ row, index }" slot="unit">
        <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="row.unit.qty" placeholder="请输入" @on-change="changeUnitUnit(row.unit.qty, 'unit', 'qty', index)" class="iviewIptWidth100 marginRight18"></InputNumber>
        <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="row.unit.unit" @on-change="changeUnitUnit(row.unit.unit, 'unit', 'unit', index)" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
          <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
        </Select>
        <span class="xiegang">/</span>
        <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="row.unit.dose_unit" @on-change="changeUnitUnit(row.unit.dose_unit, 'unit', 'dose_unit', index)" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
          <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
        </Select>
        <span class="spin">(单位)</span>
      </template>

      <template slot-scope="{ row, index }" slot="packing_size">
        <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="row.packing_size.qty" placeholder="请输入" @on-change="changeUnitUnit(row.packing_size.qty, 'packing_size', 'qty', index)" class="iviewIptWidth100 marginRight18"></InputNumber>
        <i-input class="iviewIptWidth100" v-model="row.unit.unit" disabled></i-input>
        <span class="xiegang">/</span>
        <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="row.packing_size.dose_unit" @on-change="changeUnitUnit(row.packing_size.dose_unit, 'packing_size', 'dose_unit', index)" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
          <Option v-for="(item, index) in unitList" :value="item.label" :key="index">{{ item.value }}</Option>
        </Select>
        <span class="spin">(单位)</span>
      </template>
      <template slot-scope="{ row, index }" slot="valid_days">
        <InputNumber :active-change="false" :max="999999" :min="1" :precision="0" v-model="row.valid_days" placeholder="请输入" @on-change="changeUnitUnit(row.valid_days, 'valid_days', '', index)" class="iviewIptWidth100 marginRight18"></InputNumber><span class="spin">(天)</span>
      </template>
    </Table> -->
    <!--    查看图片-->
    <lookup-image v-if="imgStatus && postFrom.record_file_url" @closeImg="imgStatus = false" title="注册证" :imgUrl="postFrom.record_file_url" @></lookup-image>
    <!--    条码维护-->
    <code-maintain v-if="codeStatus" :ruleList="ruleList" :master_barcode_identifier="master_barcode_identifier" :master_barcode="master_barcode" :secondary_barcode_rule="secondary_barcode_rule" @closeRule="codeStatus = false" @sureBrn="sureCode"></code-maintain>
    <!-- 删除规格型号 -->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!-- 禁止 -->
    <tips-component showModal="ban" v-if="banStatus" @cancleBtn="banStatus = false" @sureBrn="sureBan"></tips-component>
    <!-- 启用 -->
    <tips-component showModal="start" v-if="startStatus" @cancleBtn="startStatus = false" @sureBrn="sureBan"></tips-component>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
import LookupImage from '@/components/lookupImage'
import CodeMaintain from '@/components/codeMaintain'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'recordCertificateP',
  components: {
    FromLabel,
    FormUpload,
    LookupImage,
    CodeMaintain,
    TipsComponent,
  },
  data() {
    return {
      banFlag: '',
      startStatus: false,
      banModelName: '',
      banStatus: false,
      height: 500,
      scrollTop: '',
      right: 'right',
      tableData: [],
      cityList: [
        {
          label: '普通耗材',
          value: '1',
        },
        {
          label: '高值耗材-介入',
          value: '2',
        },
        {
          label: '高值耗材-外科',
          value: '3',
        },
        {
          label: '设备及配件',
          value: '4',
        },
        {
          label: '眼镜类商品',
          value: '5',
        },
        {
          label: '诊断试剂',
          value: '6',
        },
        {
          label: '助听器类产品',
          value: '8',
        },
        {
          label: '其他产品',
          value: '7',
        },
      ],
      unitList: [],
      data: {},
      DeleteModelName: '',
      codeStatus: false,
      deleteStatus: false,
      ruleList: [],
      master_barcode: '',
      master_barcode_identifier: '',
      secondary_barcode_rule: [],
      imgStatus: false,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68,
        },
        {
          title: '产品编号',
          slot: 'product_model_code',
          align: 'center',
          width: '150px',
          resizable: true,
        },
        {
          title: '子编号',
          slot: 'product_code_child',
          align: 'center',
          width: '150px',
          resizable: true,
        },
        {
          title: '规格名称',
          slot: 'model_name',
          align: 'center',
          width: '150px',
          resizable: true,
        },
        {
          title: '货号/SKU',
          slot: 'item_number',
          align: 'center',
          width: '150px',
          resizable: true,
        },

        {
          title: '最小包装规格',
          slot: 'unit',
          align: 'center',
          width: '470px',
          resizable: true,
        },
        {
          title: '包装规格',
          slot: 'packing_size',
          align: 'center',
          width: '470px',
          resizable: true,
        },
        {
          title: '单价',
          slot: 'unit_price',
          align: 'center',
          width: '220px',
        },
        {
          title: '税率(%)',
          slot: 'tax_rate',
          align: 'center',
          width: '120px',
        },
        {
          title: '不含税单价',
          key: 'notax_unit_price',
          align: 'center',
          width: '150px',
          render: (h, param) => {
            return h(
              'span',
              {
                style: {
                  color: '#389AFC',
                  cursor: 'pointer',
                },
              },
              param.row.unit_price ? (param.row.unit_price / (1 + (param.row.tax_rate ? param.row.tax_rate / 100 : 0))).toFixed(4) : ''
            )
          },
        },
        {
          title: '货架有效天数（保质期）',
          slot: 'valid_days',
          align: 'center',
          width: '200px',
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          fixed: 'right',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    marginLeft: 30,
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.codeReview(param.row, param.index)
                    },
                  },
                },
                '条码维护'
              ),
              h(
                'span',
                {
                  style: {
                    marginLeft: '20px',
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      if (this.listData.length !== 0 && this.listData.length !== 1) {
                        this.DeleteModelName = param.row.model_name
                        this.DeleteIndex = param.index
                        this.deleteStatus = true
                      } else if (this.listData.length === 1) {
                        this.$Message.warning('当前产品只有一个规格，不可删除!')
                      }
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      DeleteIndex: null,
      listData: [],
      total: 0,
      postFrom: {},
      status: '',
      changeList: [],
      is_approved: null,
    }
  },
  created() {
    this.getUnit()
  },
  mounted() {
    if (this.$route.query.reviewId) {
      this.data = {
        product_review_id: this.$route.query.reviewId,
        table_type: 1,
      }
    } else {
      this.data = {
        table_type: 1,
      }
    }
    this.status = this.$route.query.status
    this.queryRuleList()
    this.$root.$on('productInfo', res => {
      this.postFrom = res
      let detali = res
      // 审核通过后不能修改
      this.is_approved = res.is_approved
      this.changeList = res.changeList || []
      let obj = {
        record_file_key: detali.record_file_key,
        record_file_name: detali.record_file_name,
        record_file_url: detali.record_file_url,
        record_code: detali.record_code,
        record_product_categories: detali.record_product_categories,
        recorder_org_code: detali.recorder_org_code,
        record_name: detali.record_name,
        record_proxy_name: detali.record_proxy_name,
        record_proxy_address: detali.record_proxy_address,
        product_desc: detali.product_desc,
        preliminary_use: detali.preliminary_use,
        record_department: detali.record_department,
        record_time: detali.record_time ? this.$moment(detali.record_time * 1000).format('YYYY-MM-DD') : '',
        record_remark: detali.record_remark,
        record_product_code: [],
        record_production_address: detali.record_production_address,
        record_creator_company_address: detali.record_creator_company_address,
        check_state: detali.check_state,
      }
      this.postFrom = JSON.parse(JSON.stringify(obj))
      // for (let key of obj) {
      //   this.postFrom[key] = obj[key]
      // }
      if (this.listData.length == 0) {
        this.listData = detali.record_product_code ? detali.record_product_code : []
        this.listData.forEach((item, index) => {
          if (!item.unit) {
            this.$set(this.listData[index], 'unit', { qty: 1, unit: '', dose_unit: '' })
          } else {
            // 是不能为null
            for (let key in item.unit) {
              if (item.unit[key] === null) {
                item.unit = { qty: 1, unit: '', dose_unit: '' }
              }
            }
          }
          if (!item.packing_size) {
            this.$set(this.listData[index], 'packing_size', { qty: 1, unit: '', dose_unit: '' })
          } else {
            // 是不能为null
            for (let key in item.packing_size) {
              if (item.packing_size[key] === null) {
                item.packing_size = { qty: 1, unit: '', dose_unit: '' }
              }
            }
          }
          // 解决inputNumber默认为1
          if (!this.listData[index].valid_days) {
            this.listData[index].valid_days = ''
          }
          if (this.listData[index].unit_price) {
            this.listData[index].unit_price = Number(this.listData[index].unit_price)
          }
        })
      }
      this.$refs.plxTable.reloadData(this.listData)
      this.$root.$off('productInfo')
    })
  },
  methods: {
    changeLicenceCode(e) {
      this.$nextTick(() => {
        if (e.target.value.indexOf('/') > -1) {
          let str = ''
          let arr = e.target.value.split('/')
          for (let i = 0; i < arr.length; i++) {
            str += arr[i]
          }
          this.postFrom.record_code = str
        }
      })
    },
    changeProductCode(e, index) {
      let str = e.target.value.trim()
      this.listData[index].product_model_code = str
    },
    changeCodeChild(e, index) {
      let str = e.target.value.trim()
      this.listData[index].product_code_child = str
    },
    changeModelnName(e, index) {
      let str = e.target.value.trim()
      this.listData[index].model_name = str
    },
    changeItemNum(e, index) {
      let str = e.target.value.trim()
      this.listData[index].item_number = str
    },
    // 输入框
    notax_unit_priceChange(row, index) {
      this.listData[index].notax_unit_price = (row.unit_price / (1 + row.tax_rate / 100)).toFixed(4)
    },
    scroll({ scrollTop, scrollLeft }) {
      this.scrollTop = scrollTop
    },
    deleteRow(row, index) {
      if (this.listData.length !== 0 && this.listData.length !== 1) {
        this.DeleteModelName = row.model_name
        this.DeleteIndex = index
        this.deleteStatus = true
      } else if (this.listData.length === 1) {
        this.$Message.warning('当前产品只有一个规格，不可删除!')
      }
    },
    // 自定义规格
    handleCreateUnit(val) {
      this.unitList.push({
        value: val.trim(),
        label: val.trim(),
      })
    },
    openChangeUnit(status, obj, name, refName) {
      // if (!status && !this.listData[obj][name]) {
      //   this.$refs[refName].query = ''
      // }
    },
    changeUnitUnit(val, key, childKey, index) {
      if (val === null || !val) {
        val = ''
      }
      if (key == 'unit' || key == 'packing_size') {
        if (childKey == 'unit') {
          this.$set(this.listData[index]['packing_size'], childKey, val)
        }
      } else {
        this.$set(this.listData[index], key, val)
      }
    },
    getUnit() {
      this.$http.get(this.$api.productUnit, null, false).then(res => {
        res.data.forEach(item => {
          let obj = {
            value: item.unit,
            label: item.unit,
          }
          this.unitList.push(obj)
        })
        if (this.unitList.length > 0) {
          this.unitList = [...new Set([...this.unitList])]
        }
      })
      console.log('数据15656')
    },
    sureDelete() {
      this.$http.post(this.$api.productVerifyProductCode, { model_name: this.DeleteModelName, review_id: this.$route.query.reviewId }, true).then(res => {
        if (res.status) {
          this.listData.splice(this.DeleteIndex, 1)
          this.$refs.plxTable.reloadData(this.listData)
          this.$refs.plxTable.pagingScrollTopLeft(this.scrollTop, 0)
        } else {
          this.$Message.warning('当前规格型号已发生业务往来，不允许删除')
        }
        this.deleteStatus = false
      })
    },
    // 时间改变
    changeTime(e) {
      this.postFrom.record_time = e
    },
    // 上传注册证
    getKey(key, name, url) {
      this.postFrom.record_file_key = key
      this.postFrom.record_file_name = this.postFrom.record_code
      this.postFrom.record_file_url = url
    },
    sureCode(master_barcode_identifier, master_barcode, secondary_barcode_rule) {
      this.codeStatus = false
      this.$set(this.listData[this.addRuleIndex], 'master_barcode_identifier', master_barcode_identifier)
      this.$set(this.listData[this.addRuleIndex], 'master_barcode', master_barcode)
      this.$set(this.listData[this.addRuleIndex], 'secondary_barcode_rule', secondary_barcode_rule)
      console.log(this.listData)
    },
    // 条码维护
    codeReview(row, index) {
      this.master_barcode_identifier = row.master_barcode_identifier
      this.master_barcode = row.master_barcode
      this.secondary_barcode_rule = row.secondary_barcode_rule ? row.secondary_barcode_rule : []
      this.addRuleIndex = index
      this.codeStatus = true
    },
    banGoods(row, index) {
      this.banFlag = row.check_status
      this.banModelName = row.model_name
      if (row.check_status == 1) {
        this.banStatus = true
      } else {
        this.startStatus = true
      }
    },
    getDetail() {
      let data = {
        review_id: this.$route.query.reviewId,
      }
      this.$http.get(this.$api.productProductDetail, data, true).then(res => {
        this.listData.forEach((item, index) => {
          item.check_status = res.data.record_product_code[index].check_status
        })
      })
    },
    // 调用接口拿返回数据
    // static/product/product_detail?review_id=1670
    sureBan() {
      let data = {
        model_name: this.banModelName,
        review_id: this.$route.query.reviewId,
      }
      this.$http.post(this.$api.productCodeChange, data, true).then(res => {
        if (res.status) {
          if (this.banFlag == 1) {
            this.$Message.success(`禁用成功`)
            this.banStatus = false
            this.getDetail()
            // this.$parent.$refs.productFrame.queryDetail()
          } else {
            this.$Message.success(`启用成功`)
            this.startStatus = false
            this.getDetail()
            // this.$parent.$refs.productFrame.queryDetail()
          }
        } else {
          if (this.banFlag == 1) {
            this.$Message.success(`禁用失败`)
            this.banStatus = false
          } else {
            this.$Message.success(`启用失败`)
            this.startStatus = false
          }
        }
        // 调用父盒子刷新
      })
    },
    queryRuleList() {
      this.$http.get(this.$api.productCodeRule, this.queryFrom, true).then(res => {
        this.ruleList = res.data
      })
    },
    openFile() {
      window.open(this.postFrom.record_file_url)
    },
    // 输入框改变
    changeIpt(e, name, index) {
      if (typeof e == 'object') {
        if (name == 'unit_price' || name == 'tax_rate') {
          if (e.target.value * 1 < 0) {
            this.$set(this.listData[index], name, '1')
            return
          } else {
            this.$set(this.listData[index], name, e.target.value)
            return
          }
        }
        this.$set(this.listData[index], name, e.target.value)
      } else {
        if (name == 'unit_price' || name == 'tax_rate') {
          if (e * 1 < 0) {
            this.$set(this.listData[index], name, '1')
            return
          } else {
            this.$set(this.listData[index], name, e)
            return
          }
        }
        this.$set(this.listData[index], name, e)
      }
    },
  },
}
</script>

<style scoped lang="less">
.my-input-sc {
  display: inline-block;
  line-height: 36px;
  height: 36px;
  -webkit-appearance: none;
  background-color: #ffffff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  outline: none;
  margin-top: 6px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  white-space: pre;
}
.textLeft {
  text-align: left;
  padding-left: 5px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight18 {
  margin-right: 18px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.iviewIptWidth100 {
  width: 75px;
  height: 38px;
  line-height: 38px;
}
.marginRight18 {
  margin-right: 18px;
}
.spin {
  margin-left: 20px;
  font-size: 14px;
  color: #525b6d;
}
.xiegang {
  margin: 0 18px;
  color: #ccc;
}
.fontColorred {
  color: red;
}
/deep/ .ivu-table-wrapper {
  overflow: inherit !important;
}
/deep/ .ivu-input-number-handler-wrap {
  display: none !important;
}
.lastDiv {
  margin-left: -10px;
  display: flex;
  justify-content: space-between;
  div {
    margin: 0 10px;
    color: #389afc;
    cursor: pointer;
  }
}
.redColor {
  color: red;
}
</style>
